import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Badge, Button, Table, Modal, Tooltip, Icon } from '@syneto/compass-react/lib';
import { deleteProductForUser, getProducts } from '../../../../data/product/actions';
import MaintenanceStatusFormatter from '../../../../helpers/MaintenanceStatusFormatter';
import OSVersionParser from '../../../../helpers/OSVersionParser';
import Preloader from '../../../../components/preloader';
import AdminProductDeleteModal from './AdminProductDeleteModal';
import ProductDetails from './AdminProductDetails';
import CurrentlyActiveMaintenanceService from '../../../../helpers/CurrentlyActiveMaintenanceService';
import {
	pushDangerNotification,
	pushSuccessfulNotification
} from '../../../../data/notifications/actions';
import { GET_PRODUCTS_DEFAULT_QUERY_PARAMS } from '../../../../constants/queryParams';

function ProductsTableAdmin(props) {
	const { loading, products, pagination, getProducts } = props;
	const [product, setProduct] = useState(null);
	const [showDetailsModal, toggleDetailsModal] = useState(false);
	const [showDeleteModal, toggleDeleteModal] = useState(false);
	const [queryParams, setQueryParams] = useState(GET_PRODUCTS_DEFAULT_QUERY_PARAMS);

	useEffect(() => {
		getProducts(queryParams);
	}, [queryParams]);

	const openDeleteModal = (product) => {
		setProduct(product);
		toggleDeleteModal(true);
	};

	const closeDeleteModal = () => {
		toggleDeleteModal(false);

		if (!showDetailsModal) {
			setProduct(null);
		}
	};

	const openProductDetailsModal = (product) => {
		setProduct(product);
		toggleDetailsModal(true);
	};

	const closeProductDetailsModal = () => {
		setProduct(null);
		toggleDetailsModal(false);
	};

	const deleteProduct = (productId) => {
		return props.deleteProductForUser(productId).then(() => {
			closeProductDetailsModal();
			closeDeleteModal();
			getProducts();
			props.dispatch(pushSuccessfulNotification('Product deleted successfully.'));
		}).catch((error) => {
			props.dispatch(pushDangerNotification(`Could not delete product. Reason: ${error.message}`));
		});
	};

	const renderStar = (cell, row) => {
		const { plan } = CurrentlyActiveMaintenanceService(row.maintenanceService);

		if (plan && plan.synetoInternalName.toLowerCase().includes('premium')) {
			return (
				<Tooltip key={index} text="Premium" placement="top">
					<span>
						<Icon
							key={index}
							name="fa fa-star"
							className="text-warning"
						/>
					</span>					
				</Tooltip>
			);
		}
	};

	const renderStatus = (cell, row) => {
		const { expiresOn } = CurrentlyActiveMaintenanceService(row.maintenanceStatus);
		
		return (
			<Tooltip text={MaintenanceStatusFormatter.setTooltipInformation(expiresOn)}
				placement="top">
				<Badge appearance={MaintenanceStatusFormatter.setStyle(MaintenanceStatusFormatter.maintenanceStatus(row.maintenanceStatus))}>
					{MaintenanceStatusFormatter.maintenanceStatus(row.maintenanceStatus)}
				</Badge>
			</Tooltip>
		);
	};

	const renderOsVersion = (cell, row) => { return OSVersionParser.parseOSVersion(row.osVersion); };
	const renderEsxiVersion = (cell, row) => { return OSVersionParser.parseOSVersion(row.esxiVersion); };
	const renderHardwareId = (cell, row) => { return row?.hardwareId ?? 'N/A'; };
	const renderTitle = (cell, row) => { return row?.model?.title ?? 'N/A'; };
	const renderName = (cell, row) => { return row?.company?.name ?? 'N/A'; };

	const renderActions = (cell, row) => {
		return (
			<>
				<Button role="tertiary" onClick={() => { return openProductDetailsModal(row); }}>View</Button>
				<Button role="tertiary" onClick={() => { return openDeleteModal(row); }}>Delete</Button>
			</>
		);
	};

	const handleTableChange = (type, { page, sizePerPage, searchText }) => {
		switch (type) {
			case 'pagination': {
				handlePagination(page, sizePerPage);
				return;
			}
			case 'search': {
				handleSearch(searchText);
				return;
			}
		}
	};

	const handlePagination = (page, sizePerPage) => {
		const start = (page - 1) * sizePerPage;

		setQueryParams({
			...queryParams,
			start: start >= 0 ? start : 0,
			limit: sizePerPage
		});
	};

	const handleSearch = (value) => {
		setQueryParams({
			...queryParams,
			searchFor: value
		});
	};

	const emptyTableState = () => {
		return loading 
			? <Preloader dataIsLoading={true} />
			: 'No products found';
	};

	return (
		<div id="products" className="p-4 bg-white">
			<Table
				data={products ?? []}
				remote
				pagination={{
					sizePerPageList: [15, 30, 60, 100, 200],
					sizePerPage: pagination?.itemsPerPage,
					showTotal: true,
					page: pagination?.currentPage,
					totalSize: pagination?.totalItems
				}}
				keyField="id"
				search={{ defaultSearch: queryParams.searchFor }}
				onTableChange={handleTableChange}
				noDataIndication={emptyTableState()}
			>
				<Table.Col field="synetoInternalName" align="left" width="40px" renderCell={renderStar} />
				<Table.Col field="expiresOn" align="left" renderCell={renderStatus}>Status</Table.Col>
				<Table.Col field="serialNumber" align="left">Serial number</Table.Col>
				<Table.Col field="osVersion" align="left" renderCell={renderOsVersion}>SynetoOS Version</Table.Col>
				<Table.Col field="esxiVersion" align="left" renderCell={renderEsxiVersion}>ESXi Version</Table.Col>
				<Table.Col field="hardwareId" align="left" renderCell={renderHardwareId}>Hardware ID</Table.Col>
				<Table.Col field="title" align="left" renderCell={renderTitle}>Model</Table.Col>
				<Table.Col field="name" align="left" renderCell={renderName}>Customer</Table.Col>
				<Table.Col field="actions" align="left" renderCell={renderActions}>Actions</Table.Col>
			</Table>

			<Modal show={showDetailsModal} size="lg" onHide={closeProductDetailsModal}>
				<Modal.Header>
					<Modal.Title>Product details</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{product && <ProductDetails product={product} />}
				</Modal.Body>

				<Modal.Footer>
					<div style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
						<Button appearance="danger" role="secondary" onClick={() => { return openDeleteModal(product); }}>Delete</Button>
						<Button onClick={closeProductDetailsModal}>Close</Button>
					</div>
				</Modal.Footer>
			</Modal>

			<AdminProductDeleteModal
				show={showDeleteModal}
				onHide={closeDeleteModal}
				onDelete={() => { return deleteProduct(product.id); }}
			/>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		products: state.products.products,
		pagination: state.products.pagination,
		loading: state.products.loading
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProducts: (queryParams) => { return dispatch(getProducts(queryParams)); },
		deleteProductForUser: (productId) => { dispatch(deleteProductForUser(productId)); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsTableAdmin);
