import { API_V2, axios, backEndAxios, restAxios } from './httpClient';

export const createProduct = (body) => {
	const payload = {
		...body,
		activationDate: moment(body.activationDate, 'DD/MM/YYYY').add(moment().utcOffset(), 'minutes').toISOString(),
		productAddons: body.productAddons?.map((addon) => {
			if (addon.startOnActivation) {
				return {
					...addon,
					startDate: null
				};
			}
			
			return addon;
		})
	};
	if (!payload.virtualAppliance) {
		delete payload.activationCode;
	}
	
	return restAxios.post(`/licensing/products`, payload);
};

export const updateProduct = (body) => {
	const payload = {
		...body,
		activationDate: moment(body.activationDate, 'DD/MM/YYYY').add(moment().utcOffset(), 'minutes').toISOString(),
		productAddons: body.productAddons?.map((addon) => {
			if (addon.startOnActivation) {
				return {
					...addon,
					startDate: null
				};
			}
			
			return addon;
		})
	};
	if (!payload.virtualAppliance) {
		delete payload.activationCode;
	}

	return restAxios.patch(`/licensing/products/${payload.id}`, payload);
};


export const getProduct = (id) => {
	return restAxios.get(`/licensing/products/${id}`);
};

let getProductsController;
export const getProducts = (queryParams) => {
	// Cancel the previous request
	getProductsController?.abort();
	
	getProductsController = new AbortController();
	const searchParams = new URLSearchParams(queryParams).toString();
	return restAxios.get(`/licensing/products?${searchParams}`, {
		signal: getProductsController.signal
	});
};

export const getProductsBySerialNumber = (listOfSerialNumbers) => {
	const data = {
		serialNumbers: listOfSerialNumbers
	};
	return backEndAxios.post(`/centralBackend/${API_V2}/product/getProductsBySerialNumber`, data);
};

export const deleteProducts = (products) => {
	if (products.length === 1) {
		return restAxios.delete(`/licensing/products/${products[0].id}`);
	}

	return restAxios.post(`/licensing/products:batchDelete`, { ids: products.map((product) => { return product.id; }) });
};

export const generateActivationCode = () => {
	return axios.get('/products/activation-keys/generate');
};

// product addons

export const getProductAddons = () => {
	return restAxios.get('/licensing/addons');
};

export const getProductAddonDetails = (id) => {
	return restAxios.get(`/licensing/addons/${id}`);
};

export const createProductAddon = (body) => {
	return restAxios.post('/licensing/addons', body);
};

export const updateProductAddon = (id, body) => {
	return restAxios.patch(`/licensing/addons/${id}`, body);
};

export const deleteProductAddons = (body) => {
	return restAxios.post('/licensing/addons:batchDelete', { ids: body });
};
